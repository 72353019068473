import { DurationDistance } from "@prisma/client"
import { create } from "zustand"

interface LoginModalStore {
  isOpen: boolean
  callBackUrl?: string
  onOpen: (callBackUrl?: string) => void
  onClose: () => void
}

const useLoginModalStore = create<LoginModalStore>(
  (set) => ({
    callBackUrl: "",
    isOpen: false,
    onOpen: (callBackUrl) =>
      set({ isOpen: true, callBackUrl }),
    onClose: () => set({ isOpen: false }),
  })
)

export default useLoginModalStore
