"use client";

import { useEffect, useState } from "react";

import useLoginModalStore from "@/hooks/auth/use-login-modal-store";

import ItineraryModal from "../itinerary-modal";
import { cn } from "@/lib/utils";
import * as React from "react";
import { Icons } from "@/components/icons";
import { signIn } from "next-auth/react";
import { buttonVariants } from "@/components/ui/button";
import FooterSignIn from "@/app/(auth)/login/components/footer-sign-in";
import Link from "next/link";
import ModalScroll from "../modal-scroll";
import PopUp from "@/components/ui/popup";
import UserAgentUtils from "@/lib/utils/user-agent-utils";
interface LoginModalProps { }

const LoginModal = (props: LoginModalProps) => {
  const [userAgent, setUserAgent] = React.useState("");
  const modal = useLoginModalStore();

  React.useEffect(() => {
    setUserAgent(window.navigator.userAgent);
    console.log("AA userAgent", window.navigator.userAgent);
  }, []);

  useEffect(() => {
    if (modal.isOpen) {
      console.log("AA login callBackUrl ", modal.callBackUrl);
    }
  }, [modal.isOpen]);

  const bodyContent = (
    <div className="flex w-full flex-col gap-2 rounded-t-3xl bg-white px-4 md:max-w-3xl">
      <div className="flex flex-row pt-[18px]">
        <div className="flex-1 text-base font-semibold leading-normal text-indigo-950">
          Đăng nhập để tiếp tục
        </div>
        <Icons.close
          className="relative size-6 cursor-pointer"
          onClick={() => {
            modal.onClose();
          }}
        />
      </div>
      <div className="flex w-full items-center bg-white pb-4">
        <div className="flex w-full flex-col gap-4">
          <p className="text-sm font-normal leading-normal text-indigo-950">
            Truy cập và quản lý lịch trình trên Laka
          </p>
          <button
            onClick={() => {
              signIn("facebook", {
                callbackUrl: modal.callBackUrl || window.location.href,
              });
            }}
            className={`${cn(
              buttonVariants({ variant: "active", size: "xl" })
            )} w-full !justify-start px-6`}
          >
            <Icons.facebook className="mr-4 size-6" />
            Tiếp tục bằng Facebook
          </button>
          {!UserAgentUtils.isWebView(userAgent) && (
            <button
              onClick={() => {
                signIn("google", {
                  callbackUrl: modal.callBackUrl || window.location.href,
                });
              }}
              className={`${cn(
                buttonVariants({ variant: "default", size: "xl" })
              )} w-full !justify-start border border-line bg-white px-6`}
            >
              <Icons.googleV2 className="mr-4 size-6" />
              <span className="text-secondary-100">Tiếp tục bằng Google</span>
            </button>
          )}

          <div className="w-full pt-2 text-center text-xs font-normal text-secondary-100">
            Bằng cách tiếp tục, bạn đã hiểu và đồng ý với{" "}
            <Link
              href="/policies#dieu-khoan-su-dung"
              target="_blank"
              className="text-action"
            >
              Điều khoản sử dụng {""}
            </Link>
            và{" "}
            <Link
              href="/policies#chinh-sach-bao-mat-thong-tin-khach-hang"
              target="_blank"
              className="text-action"
            >
              Chính sách bảo mật{" "}
            </Link>
            của Laka
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <PopUp
      isOpen={modal.isOpen}
      body={bodyContent}
      onClose={() => {
        modal.onClose();
      }}
    />
  );
};

export default LoginModal;
