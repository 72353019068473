const uuid = require("uuid");

const KEY_SEARCH_HISTORY = 'SEARCH_HISTORY';

export function getClientId(): string {
  const clientId = localStorage.getItem("CLIENT_ID");

  if (clientId) {
    return clientId;
  } else {
    const newClientId = uuid.v4();
    console.log("NEW CLIENT", newClientId);
    localStorage.setItem("CLIENT_ID", newClientId);
    return newClientId;
  }
}

export function localAddSearchHistory(text: string): void {
  if (text && text.trim()) {
    let searchHistories = localStorage.getItem(KEY_SEARCH_HISTORY);

    if (!searchHistories) {
      searchHistories = '';
    }
    // console.log("AA add search params ", text);
    const searchArray = searchHistories.split(',');
    searchArray.unshift(text);
    localStorage.setItem(KEY_SEARCH_HISTORY, Array.from(new Set<string>(searchArray).values()).slice(0,5).join(','));
  }
}

export function localGetSearchHistory(): {keyword: string}[] {
  const searchHistories = localStorage.getItem(KEY_SEARCH_HISTORY);

  if (searchHistories) {
    const histories = searchHistories.split(',').filter(item => item !== '').map(item => ({keyword: item}));
    // console.log("AA get search params ", searchHistories,  histories);
    return histories;
  } else {
    return [];
  }
}

export function localDeleteSearchHistory(): void {
  // console.log("AA del search params ");
  localStorage.setItem(KEY_SEARCH_HISTORY, "");
}


