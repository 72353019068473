"use client";

import { Icons } from "../../../../components/icons";
import { Logo } from "../../../../components/logo/logo";
import NormalDialog from "../../../../components/ui/nomal-dialog";
import Link from "next/link";
import { Fragment } from "react";

export const headerItem = [
  { value: "/tao-lich-trinh", text: "✏️ Tạo lịch trình" },
  {
    value: "/hoat-dong-dich-vu",
    text: "🏖 Hoạt động & dịch vụ",
    onClick: () => {},
  },
  // {
  //     value: "/explore-world",
  //     text: "🌏 Khám phá thế giới",
  //     onClick: () => {
  //
  //     },
  // },
];

export default function HomeMenuHeaderMobile({ isOpen, onClose, user }) {
  const body = (
    <div className="fixed inset-x-0 top-[67px] z-[999] px-4">
      <div className="flex w-full flex-col justify-center rounded-2xl bg-white p-4">
        <div className=" flex ">
          <Link
            href={"/"}
            onClick={() => {
              onClose();
            }}
            className="flex-1 pb-8 pt-4"
          >
            <Logo width={[62, 29]} height={22} />
          </Link>
          <Icons.close className="cursor-pointer" onClick={onClose} />
        </div>

        <div className="grid grid-cols-1">
          {headerItem.map((item, index) => (
            <Fragment key={item.value}>
              <Link
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  onClose();
                }}
                href={item.value}
                className="cursor-pointer text-nowrap px-3 py-2.5 text-base font-semibold text-secondary-100"
              >
                {item.text}
              </Link>
              {index === headerItem.length - 1 && (
                <div className="my-4 w-full border-t border-secondary-20" />
              )}
            </Fragment>
          ))}
        </div>

        <Link
          href={!user ? "/login" : "/personal"}
          onClick={onClose}
          className="w-full"
        >
          {user ? (
            <div className="flex gap-1 px-3 py-2.5">
              <Icons.user className="size-5" />
              <button
                className="box-border  text-nowrap bg-white  text-base font-semibold text-indigo-950 
                 "
              >
                {"Thông tin cá nhân"}
              </button>
            </div>
          ) : (
            <button
              className="box-border h-14 w-full text-nowrap rounded-full border
                         border-stroke-secondaryLight bg-white px-6 py-4 text-base font-semibold text-indigo-950 
             "
            >
              {"Đăng nhập"}
            </button>
          )}
        </Link>
      </div>
    </div>
  );

  return <NormalDialog isOpen={isOpen} onClose={onClose} body={body} />;
}
