import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";

const buttonVariants = cva(
  "text-indigo-950 inline-flex items-center justify-center font-medium rounded-md text-sm transition-colors focus-visible:outline-none disabled:bg-primary-100 disabled:pointer-events-none rounded-full",
  {
    variants: {
      variant: {
        active:
          "border border-blue-500 bg-blue-500 text-white hover:bg-blue-500/90",
        default: "bg-primary-base text-white hover:bg-primary-base/90",
        accent: "bg-accent text-primary-foreground hover:bg-accent/70",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-primary-base border-input bg-white",
        secondary: "bg-secondary-40 text-secondary-foreground",
        ghost: "hover:bg-accent/10 hover:text-accent-foreground ",
        link: "underline-offset-4 hover:underline text-primary",
      },
      size: {
        default: "h-11 py-2 px-4 ",
        tiny: "py-2 px-3 text-sm rounded-full font-medium leading-tight flex-shrink-0",
        sm: "h-10 px-4 text-sm rounded-full font-medium leading-tight flex-shrink-0",
        xs: "h-9 px-4 text-sm rounded-full font-semibold leading-tight flex-shrink-0",
        xsf: "py-2 px-4 text-sm rounded-full font-semibold leading-tight flex-shrink-0",
        lg: "h-12 px-4 text-sm font-semibold rounded-full",
        xl: "h-14 px-4 text-base font-semibold rounded-full",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
