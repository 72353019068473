"use client"

import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { getCurrentShoppingSession } from '../actions/ecom-cart-v2';
import { getClientId } from '../lib/local/laka-local-storage';
import { Icons } from './icons';
import { Badge } from './ui/badge';

function CartShoppingIcon(props) {
    const [totalItemsAdded, setTotalItemsAdded] = useState(0);
    const router = useRouter()
    useEffect(() => {
        getCurrentShoppingSession(getClientId()).then((res) => {
            if (res?._count?.items > 0) {
                setTotalItemsAdded(res._count.items);
            }
        });
    }, []);

    return (
        <div className="relative inline-flex cursor-pointer">
            <Icons.shoppingCart
                className="size-6 "
                onClick={() => {
                    router.push("/cart");
                }}
            />
            {totalItemsAdded > 0 && (
                <Badge
                    variant="destructive" // Sử dụng màu đỏ và chữ trắng
                    className="absolute -right-2 -top-2 flex size-[18px] items-center justify-center p-0 text-[10px]"
                >
                    {totalItemsAdded}
                </Badge>
            )}
        </div>
    );
}

export default CartShoppingIcon;