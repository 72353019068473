"use client";

import useHomeSearchStore from "../home/states/home-search-store";
import { Icons } from "@/components/icons";
import useLoginModalStore from "@/hooks/auth/use-login-modal-store";
import { usePathname, useRouter } from "next/navigation";
import React from "react";

type NavigationBar = {
  user: any;
};

const items = [
  { name: "Trang chủ", icon: Icons.home, path: "home", requireLogin: false },
  { name: " Cá nhân", icon: Icons.user, path: "personal", requireLogin: true },
];
const NavigationBar = ({ ...props }: NavigationBar) => {
  const router = useRouter();
  const loginModal = useLoginModalStore();
  const pathName = usePathname();
  const { isSearching } = useHomeSearchStore();

  const isActive = (href) => {
    return pathName?.includes(href) ? "active" : "";
  };

  const handleChangeNavigate = (path: string, requireLogin: boolean) => () => {
    if (requireLogin && !props.user) {
      loginModal.onOpen(`${window.location.origin}/${path}`);
      return;
    }
    router.push(path);
  };

  if (
    pathName &&
    !pathName.includes("/personal")
  ) {
    return null;
  }
  
  return (
    <div className={`${isSearching ? "opacity-0" : "opacity-1"} md:hidden`}>
      <div className="inline-flex w-full items-start justify-start bg-white py-2">
        {items.map((item) => (
          <div
            key={`menu-item-${item.path}`}
            className="inline-flex shrink grow basis-0 cursor-pointer flex-col items-center justify-start gap-0.5"
            onClick={handleChangeNavigate(item.path, item.requireLogin)}
          >
            <item.icon
              className="relative size-6"
              stroke={isActive(item.path) ? "#517CFF" : "#111B42"}
            />
            <div
              className={`self-stretch text-center text-xs font-medium ${
                isActive(item.path) ? "text-[#517CFF]" : "text-black"
              }`}
            >
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavigationBar;
