import { create } from "zustand"

interface HomeSearchStore {
  isFocused: boolean
  isSearching: boolean
  searchFinish: boolean
  ecomActivitySuggest: any,
  searchText: string,
  setSearchText: (searchText: string) => void
  setEcomActivitySuggest: (ecomActivitySuggest: any) => void
  setIsFocused: (isFocused: boolean) => void
  setSearchFinish: (searchFinish: boolean) => void
  setIsSearching: (isSearching: boolean) => void
}

const initStore = {
  isFocused: false,
  isSearching: false,
  searchFinish: false,
  searchText: "",
  ecomActivitySuggest: []
}
const useHomeSearchStore = create<HomeSearchStore>(
  (set) => ({
    ...initStore,
    setSearchText: (searchText) => set({ searchText }),
    setEcomActivitySuggest: (ecomActivitySuggest) => set({ ecomActivitySuggest }),
    setIsFocused: (isFocused) => set({ isFocused }),
    setSearchFinish: (searchFinish) => set({ searchFinish }),
    setIsSearching: (isSearching) => set({ isSearching }),
  })
)

export default useHomeSearchStore
