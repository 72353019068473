import { Icons } from "../icons";
import { useLockBody } from "@/hooks/use-lock-body";
import { Dialog, Overlay, Content } from "@radix-ui/react-dialog";

interface DialogProps {
  isOpen?: boolean;
  loading?: boolean;
  body?: React.ReactElement;
  backdrop?: string;
  maxWidth?: string;
  fullWidth?: boolean;
  onClose?: () => void;
}

const NormalDialog: React.FC<DialogProps> = ({
  isOpen,
  loading = false,
  body,
  backdrop = "bg-backdrop",
  onClose,
  maxWidth = "md:max-w-6xl",
  fullWidth = false,
}) => {
  // useLockBody()
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {isOpen && (
        <Dialog open={isOpen}>
          <Overlay
            className={`fixed inset-0 z-[100] h-svh ${backdrop} outline-none duration-0`}
            onClick={onClose}
          />
          {!loading && (
            <Content
              className={`relative z-[101] mx-auto flex items-center justify-center ${
                fullWidth && "w-full"
              }`}
            >
              <div
                className={`no-scrollbar w-full overflow-y-auto ${maxWidth} max-h-[90vh] rounded-3xl`}
              >
                {body}
              </div>
            </Content>
          )}
        </Dialog>
      )}
    </div>
  );
};

export default NormalDialog;
